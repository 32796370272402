const financialClassIndex = {
  0: "Medicare",
  1: "Medicaid",
  2: "Commercial"
}

const stages = [
  { ID: 0, Name: "Charge Entry" },
  { ID: 1, Name: "Eligibility" },
  { ID: 2, Name: "Modifier" },
  { ID: 3, Name: "AMD Output" },
  { ID: 4, Name: "Diagnosis Validity" },
  { ID: 5, Name: "Hold" },
]

const stageIndex =
{
  0: "Charge Entry",
  1: "Eligibility",
  2: "Modifier",
  3: "AMD Output",
  4: "Diagnosis Validity"
}


export { financialClassIndex, stages, stageIndex };
